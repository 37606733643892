.header{
    display: flex;
    justify-content: space-between;
}

.logo{
    width: 20rem;
    height: 6rem;
}
.left-h{
    padding: 2rem;
    padding-top: 1.5rem;
    flex: 3;                                   
    /* it means ki left-h ki width right-h se 3 times hogi coz humne yha flex 3 diya hai or right-h me flex 1 diya hai... */

    display: flex;
    flex-direction: column;
    gap: 2rem;
}
.right-h{
    flex: 1;
    position: relative;
}

.header-menu{
    display: flex;
    list-style: none;
    color: white;
    gap: 2rem;
    font-size: 1.5rem;
}

.header-menu>li:hover{
    color: var(--orange);
    cursor: pointer;
    /* user-select: none; */
}
/* ***************************************************************************************************** */


@media screen and (max-width:768px) {
    .header>:nth-child(2){
        position: fixed;
        right: 2rem;
        z-index: 99;
    }
    .header-menu{
        flex-direction: column;
        padding: 2rem;
        background-color: var(--appColor);
    }
}