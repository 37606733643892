.Reasons{
    padding: 0 2rem;
    display: flex;
    grid-template: 2rem;
}


.left-r{
    flex: 1 1;                     /*flex: 1 1 se left or right equal width lelega*/
    display: grid;
    grid-template-columns: repeat(3,1fr);
    gap: 1rem;
    grid-auto-rows: 1fr;                             /*it will make the height of each row equal but hume pehle imgs ki width set krni hogi*/
}

.left-r>img{
    object-fit: cover;                        /*isse img zoom hoke bekar ni milegi*/
}
.left-r>:nth-child(1){
    width: 16rem;
    height: 35rem;
    grid-row: 1/3;                    /*1/3 se vo start and end btata hai...*/
}
.left-r>:nth-child(2){
    width: auto;
    height: 20rem;
    grid-column: 2/4;
}
.left-r>:nth-child(3){
    width: 17.5rem;
    grid-column: 2/3;
    grid-row: 2;
}
.left-r>:nth-child(4){
    width: 12.8rem;
    grid-row: 2;
    grid-column: 3/4;
}


.right-r{
    flex: 1 1;
    text-transform: uppercase;
    gap: 1rem;
    display: flex;
    flex-direction: column;
    margin-left: 8rem;
}
.right-r>span{
    font-weight: bold;
    color: var(--orange);
    font-style: italic;
    font-size:2rem;
}
.right-r>div{
    color: white;
    font-size: 3.2rem;
    font-weight: bold;
}

.details-r{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    text-transform: capitalize;
}
.details-r>div{
    display: flex;
    font-size: 1.5rem;
    align-items: center;
    gap: 1rem;
}
.details-r>div>img{
    width: 2rem;
    height: 2rem;
}

.partners{
    display: flex;
    gap: 1rem;
}
.partners>img{
    width: 4rem;
}



@media screen and (max-width:768px) {
    .Reasons{
        flex-direction: column;
    }
    .left-r{
        grid-auto-columns: auto;
        overflow: hidden;
    }
    .left-r>:nth-child(1){
        width: 10rem;
        height: 20rem;
    }
    .left-r>:nth-child(2){
        width: 19rem;
        height: 10rem;
    }
    .left-r>:nth-child(3){
        width: 11rem;
    }
    .left-r>:nth-child(4){
        width: 7rem;
        height: 9rem;
    }
    .right-r{
        margin-left: 2rem;
    }
}