.hero{
    display: flex;
    justify-content: space-between;
}


.the-best-ad{
    margin-top: 4rem;
    background-color: #363d42;
    border-radius: 4rem;
    width: fit-content;            /*yeh utni hi width lega jitne me content pura aaje*/
    padding: 19px 19px;
    color: white;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-style: italic;
}
.the-best-ad>div{
    position: absolute;
    background-color: var(--orange);
    width: 5.4rem;
    height: 80%;
    left: 8px;
    border-radius: 3rem;
}
.the-best-ad>span{
    z-index: 1;
    font-size: 1.2rem;
    font-weight: 500;
}


.hero-text{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    font-size: 6rem;
    font-weight: bold;
    color: white;
    text-overflow: inherit;
    text-transform: uppercase;
    /* ab jese shape ko alag se style krna hai or vahi humne or bhi bhut jgh kiya hua hai toh use sidha ek baar App.css me krdo.. */
}


.hero-text>div:nth-of-type(3){
    font-size: 1.25rem;
    font-weight: 500;
    margin-top: 20px;
    text-transform: capitalize;
    letter-spacing: 1px;
    width: 80%;
}






.figures{
    display: flex;
    gap: 3rem;
}
.figures>div{
    display: flex;
    flex-direction: column;
}
.figures>div>span:nth-of-type(1){
    color: white;
    font-size: 2rem;
    font-weight: bold;
}
.figures>div>span:nth-of-type(2){
    color: var(--gray);
    text-transform: uppercase;
}





.hero-buttons{
    display: flex;
    gap: 1rem;
    /* font-weight: normal; */
}

.hero-buttons>:nth-child(1){
    color: white;
    background-color: var(--orange);
    width: 10rem;
    font-size: 1.2rem;
    border-radius: 10px;
}
.hero-buttons>:nth-child(2){
    color: white;
    background-color:transparent;
    width: 10rem;
    border: 2px solid var(--orange);
    font-size: 1.2rem;
    border-radius: 10px;
}







/* ********************************************************************************88 */
.right-h{
    position: relative;
    background-color: var(--orange);
}
.right-h>.btn{
    /* iss position koj absolute bnane ke liye right-h me position relative krna hoga */
    position: absolute;
    right: 3rem;
    top: 2rem;
    color: black;
}

.heart-rate{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    background-color: var(--darkGrey);
    padding: 1rem;
    width: fit-content;
    align-items: flex-start;
    border-radius: 5px;
    position: absolute;
    top: 7rem;
    right: 4rem;
}

.heart-rate>img{
    width: 2rem;
}

.heart-rate>:nth-child(2){
    color: var(--gray);
}
.heart-rate>:nth-child(3){
    color: white;
    font-size: 1.5rem;
}



.hero-image{
    position: absolute;
    top: 12rem;
    right: 9rem;
    width: 28rem;
    z-index: 2;
}
.hero-image-back{
    position: absolute;
    top: 5rem;
    right: 24rem;
    z-index: 1;
    width: 18rem;
}



.calories{
    
    width: 15rem;
    padding: 1rem;
    border-radius: 10px;
    background-color: var(--caloryCard);
    display: flex;
    gap: 2rem;
    position: absolute;
    top: 41rem;
    right: 42rem;
}
.calories>img{
    width: 3rem;
    height: 4rem;
}
.calories>div{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.calories>div>:nth-child(1){
    color: var(--gray);
    font-size: 1.2rem;
}
.calories>div>:nth-child(2){
    font-size: 1.5rem;
    color: white;
}



.hero-blur{
    width:41rem;
    height: 45rem;
    left: 0;
}

.left-h{
    z-index: 10;
}



/* ***********************************responsive*********************************************** */


@media screen and (max-width:768px) and (min-width:370px){
    .hero{
        flex-direction: column;
    }
    .hero-blur{
        width: 14rem;
    }
    .the-best-ad{
        margin-top: 0;
        font-size: small;
        align-self: center;
        transform: scale(0.8);
    }
    .hero-text{
        font-size: xxx-large;
        align-items: center;
        justify-content: center;
    }
    .hero-text>div:nth-of-type(3){
        font-size: small;
        font-weight: 300;
        letter-spacing: 1px;
        text-align: center;
    }
    .hero-buttons{
        justify-content: center;
    }
    .figures>div>span:nth-of-type(1){
        font-size: large;
        text-align: center;
    }
    .figures>div>span:nth-of-type(2){
        font-size: small;
        text-align: center;
    }
    .right-h{
        position: relative;
        background: none;
    }
    .heart-rate{
        left: 1rem;
        top: 2rem;
    }
    .calories{
        position: relative;
        top: 5rem;
        left: 2rem;
    }
    .calories>img{
        width: 2rem;
    }
    .calories>div>:nth-child(2){
        color: white;
        font-size: 1rem;
    }
    .hero-image{
        position: relative;
        width: 15rem;
        left: 10rem;
        top: 2rem;
        align-self: center;
    }
    .hero-image-back{
        width: 8rem;
        left: 5rem;
        opacity: 0.5;
        top: 0rem;
    }
}