.footer-container{
    position: relative;
}
.footer-container>hr{
    border: 1px solid var(--lightgray);
}
.footer{
    padding: 1rem 2rem;
    display: flex;
    flex-direction: column;
    gap: 4rem;
    align-items: center;
    justify-content: center;
    height: 20rem;
}
.social-links>img{
    width: 2rem;
    height: 2rem;
    cursor: pointer;
    padding: 0 3rem;
}
.logo-f>img{
    width: 18rem;
}

.blur-f-1{
    bottom: 0;
    right: 10%;
    width: 46rem;
    height: 25rem;
    background: rgba(255,115,0,0.2);
    /* filter: blur(2000px); */
}
.blur-f-2{
    bottom: 0;
    left: 10%;
    width: 46rem;
    height: 25rem;
    background: rgba(255,115,0,0.2);
    /* filter: blur(2000px); */
}